import profile from './../assets/shoaib_khalid.jpeg'

function About() {
    return (
        <div className="about">
            <h2 className="about__heading">About Me</h2>
            <div className="about__wrapper">
                <div className="about__photo">
                    <img src={profile} alt="Shoaib Khalid"/>
                </div>
                <div className="about__introduction">
                    <div className='about__card'>
                    <div className="about__card-header">
                        <span></span>                     
                        <span></span>  
                        <span></span>
                    </div>
                    <div className='about__text'>
                        <p>👋 Hi!, I’m Shoaib Khalid! I’m a software developer with a strong passion for web development. I love creating websites that are not only visually appealing but also highly functional and easy to use. My experience spans across WordPress, React, and full-stack development, and I’m always eager to learn new things and keep improving my skills.</p>
                        <p>I enjoy bringing designs from tools like Adobe XD and Figma to life, using HTML, CSS, JavaScript, and PHP to build responsive and optimized sites. From creating custom WordPress themes to building modular React components, I focus on making websites fast, user-friendly, and ready to scale.</p>
                        <p>In every project, I prioritize collaboration and user experience. Whether it’s integrating APIs, optimizing for SEO, or ensuring compatibility across browsers, I work to create solutions that truly meet users’ needs.</p>
                        <p>I’m excited to work on projects that make an impact. Let’s create something amazing! 🖥️💡</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;