import { useState, useEffect } from 'react'
import Project from './Project'

function Projects() {


    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    // console.log(query)

    useEffect(() => {
        fetch('./projects.json')
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json()
                }

            })
            .then(data => {
                setData(data)
                console.log(data)
            })
            .catch(error => {
                setError(error)
                console.error('Errorrrr', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    if (error) return 'Error..!'
    if (loading) return 'Loading...!'

    return (
        <div className='projects'>
            <h2 className='projects__heading'>Projects</h2>
            <div className='projects__container'>

                {data.slice(0).reverse().map((item, index) => (
                    <Project index={index} item={item} />
                ))}
            </div>

        </div>
    )
}

export default Projects;